<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M13.5798 8.7425L13.5723 8.7575C13.6023 8.51 13.6323 8.255 13.6323 8C13.6323 7.745 13.6098 7.505 13.5798 7.2575L13.5873 7.2725L15.4173 5.8325L13.5948 2.6675L11.4423 3.5375L11.4498 3.545C11.0598 3.245 10.6323 2.99 10.1673 2.795H10.1748L9.8298 0.5H6.17734L5.84734 2.8025H5.85484C5.38984 2.9975 4.96234 3.2525 4.57234 3.5525L4.57984 3.545L2.41984 2.6675L0.589844 5.8325L2.41984 7.2725L2.42734 7.2575C2.39734 7.505 2.37484 7.745 2.37484 8C2.37484 8.255 2.39734 8.51 2.43484 8.7575L2.42734 8.7425L0.852344 9.98L0.604844 10.175L2.42734 13.325L4.58734 12.4625L4.57234 12.4325C4.96984 12.74 5.39734 12.995 5.86984 13.19H5.84734L6.18484 15.5H9.8223C9.8223 15.5 9.8448 15.365 9.8673 15.185L10.1523 13.1975H10.1448C10.6098 13.0025 11.0448 12.7475 11.4423 12.44L11.4273 12.47L13.5873 13.3325L15.4098 10.1825C15.4098 10.1825 15.3048 10.0925 15.1623 9.9875L13.5798 8.7425ZM7.99984 10.625C6.55234 10.625 5.37484 9.4475 5.37484 8C5.37484 6.5525 6.55234 5.375 7.99984 5.375C9.4473 5.375 10.6248 6.5525 10.6248 8C10.6248 9.4475 9.4473 10.625 7.99984 10.625Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcSolidCog',
  props: {
    size: {
      type: [String, Number],
      default: 18,
    },
  },
}
</script>

<style scoped lang="scss"></style>
