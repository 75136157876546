var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"chipsContainer",staticClass:"w-full relative cursor-default flex items-center gap-2"},[_vm._l((_vm.chips.slice(0, _vm.chipCount)),function(chip,index){return _c('div',{key:index,ref:"chip",refInFor:true},[_vm._t("chip",function(){return [_c('div',{class:[
          _vm.chipStyle,
          chip?.background ? chip.background : 'bg-neutral-0',
          chip?.color ? chip.color : 'text-neutral-900',
        ]},[_c('p',{staticClass:"truncate",style:(_vm.maxWidth ? `max-width: ${_vm.maxWidth}px` : 'max-width: 100%')},[_vm._v(" "+_vm._s(chip.label)+" ")])])]},{"chip":chip})],2)}),(_vm.chips.length - _vm.chipCount > 0)?_c('div',{},[_vm._t("dropdown",function(){return [(_vm.chips.length - _vm.chipCount > 0 && _vm.chips.length !== 1)?_c('VMenu',{staticClass:"absolute",style:(`left: ${_vm.hiddenCount}px; top:0;`),attrs:{"instant-move":"","distance":8,"placement":"bottom-end","theme":"sm"},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{directives:[{name:"close-popper",rawName:"v-close-popper"}]},[_c('div',{staticClass:"space-y-1"},_vm._l((_vm.chips.slice(_vm.chipCount, _vm.chips.length)),function(chip,index){return _c('div',{key:index,staticClass:"flex items-center",class:[
                  _vm.chipStyle,
                  chip?.background ? chip.background : 'bg-neutral-0',
                  chip?.color ? chip.color : 'text-neutral-900',
                ]},[_vm._v(" "+_vm._s(chip.label)+" ")])}),0)])]},proxy:true}],null,false,3840560433)},[_c('div',{staticClass:"cursor-pointer h-8 flex items-center"},[_c('p',{class:_vm.showMoreButtonStyle},[_vm._v("+"+_vm._s(_vm.chips.length - _vm.chipCount))])])]):_vm._e()]},{"hiddenChips":_vm.filteredHiddenChips,"hiddenCount":_vm.chips.length - _vm.chipCount})],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }