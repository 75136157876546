<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M2 8.66602H3.33333V7.33268H2V8.66602ZM2 11.3327H3.33333V9.99935H2V11.3327ZM2 5.99935H3.33333V4.66602H2V5.99935ZM4.66667 8.66602H14V7.33268H4.66667V8.66602ZM4.66667 11.3327H14V9.99935H4.66667V11.3327ZM4.66667 4.66602V5.99935H14V4.66602H4.66667ZM2 8.66602H3.33333V7.33268H2V8.66602ZM2 11.3327H3.33333V9.99935H2V11.3327ZM2 5.99935H3.33333V4.66602H2V5.99935ZM4.66667 8.66602H14V7.33268H4.66667V8.66602ZM4.66667 11.3327H14V9.99935H4.66667V11.3327ZM4.66667 4.66602V5.99935H14V4.66602H4.66667Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcList',
  props: {
    size: {
      type: Number,
      default: 16,
    },
  },
}
</script>
