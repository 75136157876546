<template>
  <div class="bg-white">
    <div
      v-if="hasMonitoringJob && !noDefaultHeader"
      class="bg-neutral-0 shadow-lg"
    >
      <!-- CMS Header -->
      <main-header />
    </div>
    <RouterView />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainHeader from '@/views/site/search/competitor_monitoring_v2/components/MainHeader.vue'

export default {
  name: 'CompetitorMonitoringV2',
  components: {
    MainHeader,
  },
  data() {
    return {
      // TODO: check if the account has monitoring job
      hasMonitoringJob: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdminUser']),
    currentRoute() {
      return this.$route.name
    },
    noDefaultHeader() {
      const routes = ['onboarding-v2', 'all-groups']
      return routes.includes(this.currentRoute)
    },
  },
  watch: {
    currentRoute: {
      handler(route) {
        if (!this.hasMonitoringJob || route === 'onboarding-v2') {
          this.$router.push({ name: 'onboarding-v2' })
        } else if (this.hasMonitoringJob && route === 'competitor-monitoring-v2') {
          this.$router.push({ name: 'overview-v2' })
        } else {
          this.$router.push({ name: this.currentRoute })
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>
