<template>
  <div
    ref="chipsContainer"
    class="w-full relative cursor-default flex items-center gap-2"
  >
    <div
      v-for="(chip, index) in chips.slice(0, chipCount)"
      ref="chip"
      :key="index"
    >
      <slot
        name="chip"
        :chip="chip"
      >
        <div
          :class="[
            chipStyle,
            chip?.background ? chip.background : 'bg-neutral-0',
            chip?.color ? chip.color : 'text-neutral-900',
          ]"
        >
          <p
            class="truncate"
            :style="maxWidth ? `max-width: ${maxWidth}px` : 'max-width: 100%'"
          >
            {{ chip.label }}
          </p>
        </div>
      </slot>
    </div>

    <div
      v-if="chips.length - chipCount > 0"
      class=""
    >
      <slot
        name="dropdown"
        :hidden-chips="filteredHiddenChips"
        :hidden-count="chips.length - chipCount"
      >
        <VMenu
          v-if="chips.length - chipCount > 0 && chips.length !== 1"
          instant-move
          :distance="8"
          placement="bottom-end"
          theme="sm"
          class="absolute"
          :style="`left: ${hiddenCount}px; top:0;`"
        >
          <div class="cursor-pointer h-8 flex items-center">
            <p :class="showMoreButtonStyle">+{{ chips.length - chipCount }}</p>
          </div>

          <template #popper>
            <div v-close-popper>
              <div class="space-y-1">
                <div
                  v-for="(chip, index) in chips.slice(chipCount, chips.length)"
                  :key="index"
                  class="flex items-center"
                  :class="[
                    chipStyle,
                    chip?.background ? chip.background : 'bg-neutral-0',
                    chip?.color ? chip.color : 'text-neutral-900',
                  ]"
                >
                  {{ chip.label }}
                </div>
              </div>
            </div>
          </template>
        </VMenu>
      </slot>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  name: 'ProtoChips',
  props: {
    label: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: 'text-sm font-medium text-bb-text-secondary',
    },
    chips: {
      type: Array,
      required: true,
    },
    chipStyle: {
      type: String,
      default: 'rounded-full py-1 px-3',
    },
    showMoreButtonStyle: {
      type: String,
      default:
        'h-6 w-6 flex items-center justify-center bg-purple-0 rounded-sm text-bb-brand-purple font-bold hover:text-white hover:bg-bb-brand-purple duration-200 ease-linear',
    },
    showMoreButtonText: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    keywordQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chipCount: this.chips.length,
      chipsWidths: [],
      width: 0,
      resizeObserver: null,
    }
  },
  computed: {
    hiddenCount() {
      const availableChips = structuredClone(this.chipsWidths).slice(0, this.chipCount)
      let output = 0
      availableChips.forEach(chip => {
        output += chip + 10
      })

      return output
    },

    filteredHiddenChips() {
      const chips = structuredClone(this.chips)
      const count = structuredClone(this.chipCount)
      if (!this.keywordQuery.trim()) return chips.slice(count)

      const fuse = new Fuse(chips.slice(count), {
        keys: ['label'],
        threshold: 0.3, // Adjust the threshold as needed
      })

      const result = fuse.search(this.keywordQuery).map(result => result.item)

      return result || []
    },
  },
  mounted() {
    this.chipCount = this.chips.length

    this.resizeObserver = new ResizeObserver(() => {
      this.updateDivWidth()
    })
    this.resizeObserver.observe(this.$refs.chipsContainer)
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    updateDivWidth() {
      this.chipCount = 0
      const chipsContainerWidth = this.$refs.chipsContainer.clientWidth
      const chipsRef = this.$refs.chip
      const gap = 8

      let limitReached = false
      let chipsWidthsSum = 0

      if (chipsRef && !this.chipsWidths.length) {
        chipsRef.map(child => {
          this.chipsWidths.push(child.offsetWidth)
        })
      }

      this.chipsWidths.forEach(item => {
        if (chipsWidthsSum < chipsContainerWidth - 50) {
          const widthAndGap = item + gap
          const _ = chipsWidthsSum + widthAndGap
          if (_ > chipsContainerWidth - 50) limitReached = true

          if (limitReached) return
          chipsWidthsSum += widthAndGap
          this.chipCount++
        }
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
