<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="solid"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.36914 6.00261C3.36914 3.42261 5.45581 1.33594 8.03581 1.33594C10.6158 1.33594 12.7025 3.42261 12.7025 6.00261C12.7025 9.50261 8.03581 14.6693 8.03581 14.6693C8.03581 14.6693 3.36914 9.50261 3.36914 6.00261ZM9.70247 6.00261C9.70247 6.92308 8.95628 7.66928 8.03581 7.66928C7.11533 7.66928 6.36914 6.92308 6.36914 6.00261C6.36914 5.08213 7.11533 4.33594 8.03581 4.33594C8.95628 4.33594 9.70247 5.08213 9.70247 6.00261Z"
      fill="currentColor"
    />
    <path
      v-if="!solid"
      d="M8.03581 1.33325C5.45581 1.33325 3.36914 3.41992 3.36914 5.99992C3.36914 9.49992 8.03581 14.6666 8.03581 14.6666C8.03581 14.6666 12.7025 9.49992 12.7025 5.99992C12.7025 3.41992 10.6158 1.33325 8.03581 1.33325ZM4.70247 5.99992C4.70247 4.15992 6.19581 2.66659 8.03581 2.66659C9.87581 2.66659 11.3691 4.15992 11.3691 5.99992C11.3691 7.91992 9.44914 10.7933 8.03581 12.5866C6.64914 10.8066 4.70247 7.89992 4.70247 5.99992Z"
      fill="currentColor"
    />
    <path
      v-if="!solid"
      d="M8.03581 7.66659C8.95628 7.66659 9.70247 6.92039 9.70247 5.99992C9.70247 5.07944 8.95628 4.33325 8.03581 4.33325C7.11533 4.33325 6.36914 5.07944 6.36914 5.99992C6.36914 6.92039 7.11533 7.66659 8.03581 7.66659Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcPin',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    solid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss"></style>
